<template>
    <tr class="bg-gray-100 border-b border-t">
        <td class="px-4  py-1  print:text-print-body" colspan="3">
            <span v-if="showName && !label">{{ prefix }}{{ balance.name }}</span>
            <span v-else>{{ label }}</span>
        </td>
        <template v-if="currentYear && !annual">
            <td class="text-right px-4  py-1  print:text-print-body" v-for="total in balance.totals[currentYear].periods">
                <balance-total :total="total"></balance-total>
            </td>
            <td class="text-right px-4  py-1  print:text-print-body" v-if="showTotal">
                <balance-total :total="balance.totals[currentYear].total"></balance-total>
            </td>
        </template>
        <template v-else>
            <td class="text-right px-4  py-1  print:text-print-body" v-for="(year, label) in balance.totals">
                <balance-total :total="year.summary"></balance-total>
            </td>
        </template>
    </tr>
</template>

<script>
  import BalanceTotal from '@/components/reports/table/BalanceTotal.vue';

  export default {
    props: {
      prefix: {
        type: String,
        default: 'Total ',
      },
      currentYear: {
        type: String,
      },
      showName: {
        type: Boolean,
        default: true,
      },
      balance: {
        type: Object,
      },
      label: {
        type: String,
      },
      showTotal: {
        type: Boolean,
        default: true,
      },
      annual: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      BalanceTotal,
    },
  };
</script>

<style>

</style>