<template>
  <thead>
  <tr>
    <th class="px-4 py-1 text-left" colspan="3"></th>
    <th v-for="period in reportingPeriods">
      <div class="text-right px-4 py-1 print:text-right">{{ period.label }}<span class="invisible-bracket"></span></div>
    </th>
    <th class="text-right px-4  py-1  print:text-print-body bg-gray-100" v-if="showTotal">
      <div class="print:text-right">Total<span class="invisible-bracket"></span></div>
    </th>
  </tr>
  <tr v-if="includeCurrency">
    <th class="px-4 py-1 text-left" colspan="3"></th>
    <th class="text-right px-4 py-1" v-for="_ in reportingPeriods">£<span
        class="invisible-bracket"></span></th>
    <th class="text-right px-4 py-1 bg-gray-100">£<span
        class="invisible-bracket"></span></th>
  </tr>
  </thead>
</template>

<script>

export default {
  props: {
    reportingPeriods: {
      type: Array,
    },
    includeCurrency: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style>

</style>