<template>
    <div class="h-full w-full flex-col justify-center page-break-before px-4" v-if="comments.length > 0">
        <h1 class="text-lg">Comments and Notes</h1>
        <div class="w-full pt-8" v-for="comment in comments">
            <h1 class="text-lg capitalize">{{ title(comment) }}</h1>
            <section-commentary :commentary="comment"></section-commentary>
        </div>
    </div>
</template>

<script>
  import SectionCommentary from '@/components/exports/projection/SectionCommentary.vue';

  export default {
    props: {
      projection: {
        type: Object,
      },
      export: {
        type: Object,
      }
    },

    data() {
      return {
        aliases: {
          'opening_balances': 'Opening Balances',
          'income': 'Other Income',
        }
      };
    },

    methods: {
      title(commentary) {
        return this.aliases[commentary.section] || commentary.section;
      }
    },

    computed: {
      statements() {
        return this.export.value.statements;
      },
      comments() {
        return this.projection.comments.data;
      },
    },

    components: {
      SectionCommentary,
    }
  };
</script>

<style>

</style>