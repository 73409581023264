<template>
    <balance-spacer-row :show-total="showTotal" :number-of-reporting-periods="numberOfReportingPeriods">{{ balance.name }}</balance-spacer-row>
</template>

<script>
  import BalanceSpacerRow from './BalanceSpacerRow.vue';

  export default {
    props: {
      numberOfReportingPeriods: {
        type: Number,
        default: 12,
      },
      balance: {
        type: Object,
      },
      showTotal: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      BalanceSpacerRow,
    },
  };
</script>

<style>

</style>