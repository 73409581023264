<template>
    <table v-if="balances" class="text-grey-darkest lg:w-full print:w-full print:text-print-body">
      <period-headers :reporting-periods="reportingPeriods" :include-currency="true"></period-headers>
        <tbody>
        <!-- Start -->
        <!--Turnover-->
        <balance-scope :balance="balances.turnover" v-slot="{balance}">
            <balance-name-row :balance="balance" :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                              :current-year="currentYear.label">
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label"></balance-total-row>
        </balance-scope>

        <!--Cost of Goods Sold-->
        <balance-scope :balance="balances.costOfGoodsSold" v-slot="{balance}">
            <balance-name-row :balance="balance" :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                              :current-year="currentYear.label">
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label"></balance-total-row>
        </balance-scope>

        <!--Gross Profit-->
        <balance-scope :balance="balances.grossProfit" v-slot="{balance}">
            <balance-name-row :balance="balance" :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label"></balance-total-row>
        </balance-scope>

        <!--Overheads-->
        <balance-scope :balance="balances.overheads" v-slot="{balance}">
            <balance-name-row :balance="balance" :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                :current-year="currentYear.label">
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label"></balance-total-row>
        </balance-scope>

        <!--Aggregates-->
        <balance-scope :balance="balances.aggregates" v-slot="{balance, monthlyBalances}">
            <balance-spacer-row :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-spacer-row>
            <balance-data-row v-for="(data, key) in monthlyBalances"
                              :balance="data"
                              :key="balance.name + key"
                              :highlight="key === monthlyBalances.length - 1"
                              :current-year="currentYear.label"
            >
            </balance-data-row>
        </balance-scope>
        <!-- End -->
        </tbody>
    </table>
</template>

<script>
  import {month, year} from '@/app/filters';
  import BalanceScope from './table/BalanceScope.vue';
  import BalanceSpacerRow from './table/BalanceSpacerRow.vue';
  import BalanceNameRow from './table/BalanceNameRow.vue';
  import BalanceDataRow from './table/BalanceDataRow.vue';
  import BalanceTotalRow from './table/BalanceTotalRow.vue';
  import PeriodHeaders from '@/components/reports/table/PeriodHeaders.vue';
  import _find from 'lodash/find.js';

  export default {
    filters: {
      month,
      year,
    },

    props: {
      profitLoss: {
        type: Object,
      },
      years: {
        type: Array,
      },
      year: {
        type: Object,
      },
    },

    data() {
      return {};
    },

    computed: {
      currentYear() {
        return this.year;
      },

      balances() {
        return this.profitLoss.balances;
      },

      reportingPeriods() {
        let currentReportingPeriod = _find(this.years, period => period === this.year);
        return currentReportingPeriod.reporting_periods.data;
      }
    },

    components: {
      BalanceScope,
      BalanceSpacerRow,
      BalanceNameRow,
      BalanceDataRow,
      BalanceTotalRow,
      PeriodHeaders,
    }
  };
</script>

<style scoped>
    table {
        table-layout: fixed;
    }
</style>