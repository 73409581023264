<template>
    <div v-if="commentary.body">
        <p v-for="part in parts">{{ part }}</p>
    </div>
    <div v-else>
        <p class="italic">None.</p>
    </div>
</template>

<script>
  export default {
    props: {
      commentary: {
        type: Object,
      }
    },

    data() {
      return {};
    },

    computed: {
      parts() {
        if(!(this.commentary && this.commentary.body)) {
          return [];
        }

        let delimiterPattern = /([\n])/g;
        return this.commentary.body.split(delimiterPattern).filter(part => {
          return part !== '';
        });
      }
    }
  };
</script>

<style lang="css" scoped>
    p {
        @apply text-sm;
    }

    /* Force paragraph blocks to have default line break */
    p:after {
        content: "";
        display: inline-block;
        width: 0;
    }
</style>