<template>
  <tr :class="{'bg-gray-100 border-b border-t' : highlight}" v-if="!balance.annual || annual">
    <td class="px-4 py-1 print:py-0.5 text-left print:text-print-body" colspan="3">{{ balance.name }}</td>
    <template v-if="!annual">
      <td class="text-right px-4 py-1 print:py-0.5 print:text-print-body" v-for="total in periodTotals">
        <balance-total :total="total"></balance-total>
      </td>
      <td class="text-right px-4 py-1 print:py-0.5 print:text-print-body bg-gray-100" v-if="showTotal">
        <balance-total :total="currentReportingPeriod.value"></balance-total>
      </td>
    </template>
    <template v-else>
      <td class="text-right px-4 py-1 print:py-0.5 print:text-print-body" v-for="(period, key) in balance.reporting_periods.data">
        <balance-total :total="period.value"></balance-total>
      </td>
    </template>
  </tr>
</template>

<script>
import BalanceTotal from '@/components/reports/table/BalanceTotal.vue';

export default {
  props: {
    balance: {
      type: Object,
    },
    currentYear: {
      type: String,
    },
    // Whether to show the aggregated total for the full reporting period (typically the year-end total)
    showTotal: {
      type: Boolean,
      default: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    annual: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BalanceTotal,
  },
  computed: {
    currentReportingPeriod() {
      return this.balance.reporting_periods.data.find(period => period.label === this.currentYear);
    },
    periodTotals() {
      if (!this.currentReportingPeriod) {
        return undefined;
      }

      return this.currentReportingPeriod.reporting_periods.data.map(period => period.value);
    },
  },
};
</script>

<style>

</style>