<template>
  <Line :chartData="chartData" :options="options" ref="chart"></Line>
</template>

<script>
  import {LineChart as Line} from 'vue-chart-3';
  import {Chart, LineElement, LineController, CategoryScale, LinearScale, PointElement, Title, Tooltip} from 'chart.js';
  import _first from 'lodash/first';
  import colorschemes from '@/components/ui/config';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  import _find from 'lodash/find.js';
  import getColor from '@/components/ui/ChartColors.js';

  Chart.register(ChartDataLabels, LineElement, LineController, CategoryScale, LinearScale, PointElement, Title, Tooltip);

  export default {
    components: { Line },
    props: {
      cashFlow: {
        type: Object,
      },
      year: {
        type: String,
        required: false,
      },
    },

    data() {
      return {};
    },

    computed: {
      labels() {
        if(!this.year) {
          return this.cashFlow.reporting_periods.data.map(period => period.reporting_periods.data).flat().map(period => period.label);
        }

        return _find(this.cashFlow.reporting_periods.data, period => period.label === this.year);
      },
      chartData() {
        return {
          labels: this.labels,
          datasets: [
            {
              data: Object.values(this.cashByMonth),
              backgroundColor: getColor(3, 5),
              borderColor: getColor(1, 5),
            },
          ],
        };
      },
      cashByMonth() {
        const balanceCarriedForward = _first(this.cashFlow.balances.aggregates.balances.data, balance => {
          return balance.name = "Balance C/Fwd"
        });

        if(!this.year) {
          let years = Object.keys(balanceCarriedForward.totals)
          return years.reduce((carry, year) => {
            return [ ...carry, ...balanceCarriedForward.totals[year].periods]
          }, [])
        }

        return balanceCarriedForward.totals[this.year].periods;
      },
      options() {
        const vm = this;

        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              suggestedMin: 0,
              stacked: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: this.year ? 'Cash position by period' : 'Cash position by period (all time)'
            },
            legend: {
              display: false,
            },
            colorschemes,
            datalabels: {
              align: 'bottom',
              offset: 8,
              visibility: 'auto',
              clamp: true,
              clip: true,
              color: '#36A2EB',
              display: function(context) {
                return context.dataIndex % 2 === 0; // display labels with an odd index
              }
            },
            tooltip: {
              xPadding: 8,
              yPadding: 8,
              footerFontStyle: 'light',
              footerMarginTop: 12,
              callbacks: {
                footer: function(tooltipItems) {
                  // let item = _first(tooltipItems);
                  // let total = Object.values(vm.cashByMonth)[item.index];
                  //
                  // return 'Total cash in month: ' + total;
                }
              }
            },
          },
          animation: {
            duration: 0
          },
          legend: {
            display: false
          },
        };
      }
    },

    mounted() {
      // this.addPlugin(ChartDataLabels);
      // window.addEventListener('beforeprint', () =>{
      //   this.$refs.chart?.chartInstance?.resize();
      // })
    },
  };
</script>

<style>

</style>