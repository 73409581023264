<template>
  <div class="h-full w-full" v-if="statement">
    <page v-for="(year, key) in years" :key="key">
      <h2 class="text-lg px-4 py-4">Sales Plan - {{ year.label }}</h2>
      <sales-plan-year-table :ref="addTableRef" :sales-plan="statement" :year="year"
                             :years="years"></sales-plan-year-table>
    </page>
    <!-- Only show summary if there is more than one year -->
    <page v-if="years.length > 1">
      <h1 class="text-lg px-4 py-4">Sales Plan - {{ firstYearLabel }} to {{ lastYearLabel }}</h1>
      <div>
        <statement-annual-summary :balances="statement.balances" :labels="labels" :years="years"/>
      </div>
    </page>
    <page v-if="projectionExport.options.visualizations">
      <h1 v-if="years.length > 1" class="text-lg px-4 py-4">Sales Plan - {{ firstYearLabel }} to {{
          lastYearLabel
        }}</h1>
      <h1 v-else class="text-lg px-4 py-4">Sales Plan - {{ firstYearLabel }}</h1>
      <div class="h-full w-full flex flex-col flex-1 min-h-screen min-w-screen">
        <sales ref="sales" class="flex-1" :sales-plan="statement" ></sales>
<!--        style="max-height: 46%"-->
        <sales-revenue ref="salesRevenue" class="flex-1" :sales-plan="statement"></sales-revenue>
      </div>
    </page>
  </div>
</template>

<script>
import SalesPlanYearTable from '@/components/reports/SalesPlanYearTable.vue';

import _maxBy from 'lodash/maxBy';

import SalesPlanInsights from '@/components/ui/sales-plan/SalesPlanInsights.vue';
import SalesRevenue from '@/components/ui/sales-plan/charts/SalesRevenue.vue';
import Sales from '@/components/ui/sales-plan/charts/Sales.vue';
import Page from '@/components/exports/projection/Page.vue';
import StatementAnnualSummary from '@/components/reports/StatementAnnualSummary.vue';
import labels from '@/components/reports/labels';

import useProjectionExport from './export-utils.js';
import {toRefs, onMounted, computed, ref} from 'vue';

export default {
  props: {
    export: {
      type: Object,
    },
  },

  setup(props) {
    const {export: projectionExport} = toRefs(props);

    const {firstYearLabel, lastYearLabel, years} = useProjectionExport(projectionExport);
    const statement = computed(() => projectionExport.value.statements.salesPlan);

    const tables = ref([]);
    const addTableRef = (ref) => tables.value.push(ref);
    const sales = ref();
    const salesRevenue = ref();

    onMounted(() => {
      if(!tables.value) {
        return;
      }

      let max = _maxBy(tables.value, (table) => {
        return table.$el.clientWidth;
      });

      tables.value.forEach(table => {
        table.$el.style.minWidth = max.$el.clientWidth + 'px';
      });

      if(sales.value) {
        sales.value.$el.style.width = max.$el.clientWidth + 'px';
      }

      if(salesRevenue.value) {
        salesRevenue.value.$el.style.width = max.$el.clientWidth + 'px';
      }
    })

    return {
      firstYearLabel,
      lastYearLabel,
      years,
      statement,
      labels: labels.salesPlan,
      projectionExport,

      tables,
      addTableRef,
      sales,
      salesRevenue,
    }
  },

  components: {
    SalesPlanYearTable,
    SalesPlanInsights,
    SalesRevenue,
    Sales,
    Page,
    StatementAnnualSummary,
  },
};
</script>

<style scoped>
table {
  /*table-layout: fixed;*/
  width: 100%;
}
</style>