<template>
    <div v-if="statement">
        <page :key="key" v-for="(year, key) in years">
            <h2 class="text-lg px-4 py-2">Profit & Loss - {{ year.label }}</h2>
            <profit-loss-year-table :profit-loss="statement"
                                    :year="year"
                                    :years="years"
                                    :ref="addTableRef"
            ></profit-loss-year-table>
        </page>
        <page>
          <h1 class="text-lg px-4 py-2" v-if="years.length > 1">Profit & Loss - {{ firstYearLabel }} to {{ lastYearLabel }}</h1>
          <h1 class="text-lg px-4 py-2" v-else>Profit & Loss - {{ firstYearLabel }}</h1>
          <statement-annual-summary :balances="statement.balances" :years="years" :labels="labels"/>
        </page>
    </div>
</template>

<script>
  import ProfitLossYearTable from '@/components/reports/ProfitLossYearTable.vue';
  import Page from '@/components/exports/projection/Page.vue';
  import _maxBy from 'lodash/maxBy';
  import StatementAnnualSummary from '@/components/reports/StatementAnnualSummary.vue';
  import labels from '@/components/reports/labels';
  import {computed, onMounted, ref, toRefs} from 'vue';
  import useProjectionExport from './export-utils.js';

  export default {
    props: {
      export: {
        type: Object,
      }
    },

    setup(props) {
      const {export: projectionExport} = toRefs(props);

      const {firstYearLabel, lastYearLabel, years} = useProjectionExport(projectionExport);
      const statement = computed(() => projectionExport.value.statements.profitLoss);

      const tables = ref([]);
      const addTableRef = (ref) => tables.value.push(ref);

      onMounted(() => {
        if(tables.value) {
          let max = _maxBy(tables.value, (table) => {
            return table.$el.clientWidth;
          });

          tables.value.forEach(table => {
            table.$el.style.minWidth = max.$el.clientWidth + 'px';
          });
        }
      })

      return {
        firstYearLabel,
        lastYearLabel,
        years,
        statement,
        labels: labels.profitLoss,

        tables,
        addTableRef,
      }
    },

    components: {
      ProfitLossYearTable,
      Page,
      StatementAnnualSummary,
    },
  };
</script>

<style scoped>
    table {
        /*table-layout: fixed;*/
        width: 100%;
    }
</style>