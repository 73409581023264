<template>
  <Bar :chartData="chartData" :options="options"></Bar>
</template>

<script>
import {BarChart as Bar} from 'vue-chart-3';
import {BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import _first from 'lodash/first';
import _find from 'lodash/find.js';
import colorschemes from '@/components/ui/config';
import getColor from '@/components/ui/ChartColors.js';

Chart.register(BarElement, BarController, CategoryScale, LinearScale, Title, Tooltip, Legend);

  export default {
    components: {Bar},
    props: {
      salesPlan: {
        type: Object,
      },
      year: {
        type: String,
        required: false,
      },
    },

    data() {
      return {};
    },

    computed: {
      yearReportingPeriod() {
        return _find(this.salesPlan.reporting_periods.data, period => period.label === this.year);
      },
      labels() {
        if(!this.year) {
          return this.salesPlan.reporting_periods.data.map(period => period.reporting_periods.data).flat().map(period => period.label);
        }

        return this.yearReportingPeriod.reporting_periods.data.map(period => period.label);
      },
      chartData() {
        return {
          // need to allow for all years
          labels: this.labels,//this.yearReportingPeriod.reporting_periods.data.map(period => period.label),
          datasets: [
            ...Object.entries(this.salesByLineItemByMonth).map((pair, i) => {
                const [name, balance] = pair;

                return {
                  label: `${name}`,
                  data: Object.values(balance),
                  backgroundColor: getColor(i, Object.values(this.salesByLineItemByMonth).length)
                }
            }),
          ],
        };
      },
      salesByMonth() {
        if(!this.year) {
          let years = Object.keys(this.salesPlan.balances.unitSales.totals)
          return years.reduce((carry, year) => {
            return [ ...carry, ...this.salesPlan.balances.unitSales.totals[year].periods]
          }, [])
        }

        return this.salesPlan.balances.unitSales.totals[this.year].periods;
      },
      salesByLineItemByMonth() {
        let sales = {};
        this.salesPlan.balances.unitSales.balances.data.forEach(lineItemBalance => {
          if(!this.year) {
            let years = Object.keys(this.salesPlan.balances.unitSales.totals)

            let totals = []
            years.forEach(year => {
              totals = [...totals, ...lineItemBalance.totals[year].periods]
            })

            return sales[lineItemBalance.name] = totals
          }

          return sales[lineItemBalance.name] = lineItemBalance.totals[this.year].periods;
        });

        return sales;
      },
      options() {
        const vm = this;

        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: {stacked: true},
            yAxes: {stacked: true},
          },
          plugins: {
            colorschemes,
            title: {
              display: true,
              text: this.year ? 'Unit Sales by period' : 'Unit Sales by period (all time)'
            },
            tooltip: {
              xPadding: 8,
              yPadding: 8,
              footerFontStyle: 'light',
              footerMarginTop: 12,
              callbacks: {
                footer: function(tooltipItems) {
                  let item = _first(tooltipItems);
                  let total = Object.values(vm.salesByMonth)[item.dataIndex];

                  return 'Total sales in period: ' + total;
                }
              }
            },
            datalabels: {
              display: false
            },
          },
          animation: {
            duration: 0
          },
        }
      }
    },

    mounted() {
      // window.addEventListener('beforeprint', () =>{
      //   this.$data._chart.resize();
      // })
    },
  };
</script>

<style>

</style>