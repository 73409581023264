<template>
    <table class="text-gray-darkest lg:w-full print:w-full print:text-print-body" v-if="cashFlow">
        <period-headers :reporting-periods="reportingPeriods" :include-currency="true"></period-headers>
        <tbody>
        <template v-for="(balance, key) in balances">
            <balance-name-row :balance="{name: labels[key]}" :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row :balance="data"
                              :current-year="currentYear.label"
                              :key="balance.name + balanceKey"
                              v-for="(data, balanceKey) in balance.balances.data"
                              :show-total="true"
                              :highlight="key === lastKey && balanceKey === balance.balances.data.length -1"
                :class="{'bg-gray-lighter': key === lastKey && balanceKey === balance.balances.data.length -1}"
            >
            </balance-data-row>
            <balance-total-row
                    :balance="balance"
                    :label="'Total ' + labels[key]"
                    :current-year="currentYear.label"
                    v-if="key !== lastKey"
                    :show-total="true"
            ></balance-total-row>
        </template>
        </tbody>
    </table>
</template>

<script>
  import BalanceSpacerRow from './table/BalanceSpacerRow.vue';
  import BalanceNameRow from './table/BalanceNameRow.vue';
  import BalanceDataRow from './table/BalanceDataRow.vue';
  import BalanceTotalRow from './table/BalanceTotalRow.vue';
  import PeriodHeaders from '@/components/reports/table/PeriodHeaders.vue';
  import _find from 'lodash/find.js';

  export default {
    props: {
      cashFlow: {
        type: Object,
      },
      years: {
        type: Array,
      },
      year: {
        type: Object,
      },
    },

    computed: {
      currentYear() {
        return this.year;
      },

      labels() {
        return {
          income: 'Income',
          expenditure: 'Expenditure',
          aggregates: 'Totals',
        }
      },

      balances() {
        return this.cashFlow.balances;
      },

      lastKey() {
        return Object.keys(this.balances)[Object.keys(this.balances).length - 1]
      },

      reportingPeriods() {
        let currentReportingPeriod = _find(this.years, period => period === this.year);
        return currentReportingPeriod.reporting_periods.data;
      },
    },
    components: {
      BalanceSpacerRow,
      BalanceNameRow,
      BalanceDataRow,
      BalanceTotalRow,
      PeriodHeaders,
    },
  };
</script>

<style scoped>
    table {
        table-layout: fixed;
    }
</style>