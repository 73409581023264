<template>
  <slot :balance="balance" :monthly-balances="monthlyBalances"></slot>
</template>
<script>
  import {toRefs} from 'vue';
  export default {
    props: {
      balance: {
        type: Object,
      }
    },
    setup(props) {
      const {balance} = toRefs(props);

      if(!balance) {
        return null
      }

      const monthlyBalances = balance.value.balances.data.filter(balance => {
        return !balance.annual;
      })

      return {
        balance,
        monthlyBalances,
      }
    },
  };
</script>

<style>

</style>