<template>
  <Bar :chartData="chartData"></Bar>
</template>

<script>
import {BarChart as Bar} from 'vue-chart-3';
import {Chart, BarElement, BarController, CategoryScale, LinearScale} from 'chart.js';
import moment from 'moment';
import {month} from '@/app/filters';
import colorschemes from '@/components/ui/config';

Chart.register(BarElement, BarController, CategoryScale, LinearScale);

export default {
  props: {
    salesPlan: {
      type: Object,
    },
    year: {
      type: String,
    },
  },

  data() {
    return {};
  },

  components: {
    Bar,
  },

  computed: {
    chartData() {
      return {
        labels: Object.keys(this.revenueByMonth).map((index) => {
          return month(moment(index));
        }),
        datasets: [
          {
            label: 'New Revenue by month',
            // backgroundColor: '#00f8f0',
            data: Object.values(this.revenueByMonth),
          },
          {
            label: 'Cumulative new revenue by month',
            // backgroundColor: '#85f861',
            data: Object.values(this.yearlyCumulativeRevenueByMonth),
          },
        ],
      };
    },
    yearTotals() {
      return this.salesPlan.balances.totalSales.totals[this.year];
    },
    revenueByMonth() {
      return this.yearTotals;
    },
    yearlyCumulativeRevenueByMonth() {
      let totals = {};

      Object.entries(this.yearTotals).reduce(function(carry, pair) {
        const [index, current] = pair;
        totals[index] = carry + current;
        return totals[index];
      }, 0);

      return totals;
    },
  },
};
</script>

<style>

</style>