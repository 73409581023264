export default {
  cashFlow: {
    income: 'Income',
    expenditure: 'Expenditure',
  },
  salesPlan: {
    totalSales: 'Sales',
    costOfGoodsSold: 'Cost of Goods Sold',
    unitSales: 'Unit Sales',
  },
  profitLoss: {
    turnover: 'Turnover',
    costOfGoodsSold: 'Cost of Goods Sold',
    grossProfit: 'Gross Profit',
    overheads: 'Overheads',
  },
  balanceSheet: {
    currentAssets: 'Current Assets',
    currentLiabilities: 'Current Liabilities',
    netAssets: 'Net Assets',
    capital: 'Shareholder\'s Funds',
  },
};
