<template>
  <div class="h-full w-full export text-xs">
    <div class="h-full w-full" v-if="options.cover">
      <cover :organisation="organisation"
             :projection="projection"
      />
    </div>
    <div>
      <sales-plan :export="export"
      />
    </div>
    <div>
      <cash-flow :export="export"
      />
    </div>
    <div>
      <profit-loss :export="export"/>
    </div>
    <div>
      <balance-sheet :export="export"/>
    </div>
    <div>
      <commentary v-if="options.commentary"
                  :export="export"
                  :projection="projection"
      />
    </div>
  </div>
</template>

<script>
import Cover from '@/components/exports/projection/Cover.vue';
import SalesPlan from '@/components/exports/projection/SalesPlan.vue';
import CashFlow from '@/components/exports/projection/CashFlow.vue';
import ProfitLoss from '@/components/exports/projection/ProfitLoss.vue';
import BalanceSheet from '@/components/exports/projection/BalanceSheet.vue';
import Commentary from '@/components/exports/projection/Commentary.vue';

export default {
  props: {
    organisation: {
      type: Object,
    },

    projection: {
      type: Object,
      required: true,
    },

    export: {
      type: Object,
      required: true,
    },
  },

  computed: {
    statements() {
      return this.export.statements;
    },

    options() {
      return this.export.options;
    },

    visiblePeriods() {
      let periods = this.export.options.periods.data;

      return periods.map(period => period.label);
    }
  },

  data() {
    return {};
  },

  components: {
    Cover,
    SalesPlan,
    CashFlow,
    ProfitLoss,
    BalanceSheet,
    Commentary,
  },
};
</script>

<style>
html, body {
  height: 100vh;
}

.export {
  -webkit-print-color-adjust: exact;
}

.page-break-after {
  page-break-after: always;
  page-break-inside: avoid;
  border-bottom: 1px solid #0f6b94;
}

.page-break-before {
  page-break-before: always;
  page-break-inside: avoid;
  border-top: 1px solid #0f6b94;
}

@media print {
  /*table {page-break-inside: avoid;}*/
  /*table{font-size:1vw;}*/
  .portrait {
    page: portrait !important;
  }

  .landscape {
    page: landscape !important;
  }

  .page-break-after {
    border-bottom: none;
  }

  .page-break-before {
    border-top: none;
  }

  canvas.chart-canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
  }
}


</style>