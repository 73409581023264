import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import 'intersection-observer';
import regeneratorRuntime from 'regenerator-runtime/runtime';
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.regeneratorRuntime = regeneratorRuntime;

Chart.unregister(ChartDataLabels);

// import 'chartjs-plugin-colorschemes';
// window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
//
// try {
//     window.$ = window.jQuery = require('jquery');
//
//     require('bootstrap')
// } catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = axios;

window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = Pusher;

export const echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
});

window.Echo = echo;

Date.prototype.toIso8601String = () => {
  // let offset = this.getTimezoneOffset()/60;
  // return this.format('Y-m-d\Th:mm:ss+')
};
