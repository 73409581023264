import {Chart} from 'chart.js';

// import colorSchemesPlugin from 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
// import colorSchemes from 'chartjs-plugin-colorschemes/src/colorschemes/index';
// export const CHARTS_THEME = 'tailwind.blue';
// export const chartsColorScheme = CHARTS_THEME;
//
// import resolveConfig from 'tailwindcss/resolveConfig';
// import tailwind from '@/tailwind.js';
//
// const { colors } = resolveConfig(tailwind).theme;
//
// colorSchemes.tailwind = {
//   BlueSet: [
//     colors.blue[900],
//     colors.blue[200],
//     colors.blue[300],
//     colors.blue[400],
//     // colors.blue[500],
//     // colors.blue[600],
//     // colors.blue[800],
//     colors.blue[700],
//   ],
// };

// Chart.register(colorSchemesPlugin);
export default {
  scheme: 'tailwind.BlueSet',
  reverse: true,
};
