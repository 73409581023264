import {computed} from 'vue';

export default function(projectionExport) {
  const years = computed(() => projectionExport.value?.options.periods.data);
  const firstYearLabel = computed(() => years.value?.[0].label);
  const lastYearLabel = computed(() => years.value?.[years.value.length - 1].label);

  return {
    years,
    firstYearLabel,
    lastYearLabel,
  }
}