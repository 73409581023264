<template>
    <div>
        <!--<h2>Sales Plan Insights</h2>-->
        <div class="md:flex" v-if="salesPlan.balances.totalSales && salesPlan.balances.totalSales.totals[year]">
            <div class="md:w-1/2 pr-2">
                <sales-revenue class="h-104" :sales-plan="salesPlan" :year="year"></sales-revenue>
<!--                <revenue :sales-plan="salesPlan" :year="year"></revenue>-->
            </div>
            <div class="md:w-1/2 pl-2">
                <sales class="h-104" :sales-plan="salesPlan" :year="year"></sales>
            </div>
        </div>
        <div class="flex" v-if="salesPlan.balances.totalSales && showAllTime">
            <div class="w-full mt-8">
                <sales-revenue class="h-104" :sales-plan="salesPlan"></sales-revenue>
            </div>
        </div>
    </div>
</template>

<script>
  import Revenue from './charts/Revenue.vue'
  import Sales from './charts/Sales.vue'
  import SalesRevenue from './charts/SalesRevenue.vue'

  export default {
    props: {
      showAllTime: {
        type: Boolean,
        default: true,
      },

      salesPlan: {
        type: Object,
      },
      year: {
        type: String,
        required: true,
      }
    },

    components: {
      Revenue,
      Sales,
      SalesRevenue,
    },

  };
</script>

<style>

</style>