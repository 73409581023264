<template>
    <div v-if="total < 0" class="negative" style="direction: rtl">
        <span class="bracket">(</span>{{ Math.abs(total) }}<span class="bracket">)</span>
    </div>
    <div v-else style="direction: rtl">
        <span class="bracket">(</span>{{ Math.abs(total) }}<span class="bracket">)</span>
    </div>
</template>

<script>
  export default {
    props: {
      total: {
        required: true,
        type: Number,
      }
    },
  };
</script>

<style>

</style>