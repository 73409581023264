<template>
    <table v-if="statement.balances" class="lg:w-full print:w-full print:text-print-body">
        <period-headers :reporting-periods="reportingPeriods" :show-total="false"></period-headers>
        <tbody>
        <balance-scope :balance="statement.balances.currentAssets" v-slot="{balance}">
            <balance-name-row :balance="balance" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                              :current-year="currentYear.label"
                              :show-total="false"
            >
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label" :show-total="false"></balance-total-row>
        </balance-scope>
        <balance-scope :balance="statement.balances.currentLiabilities" v-slot="{balance}">
            <balance-name-row :balance="balance" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                              :current-year="currentYear.label"
                              :show-total="false">
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label" :show-total="false"></balance-total-row>
        </balance-scope>
        <balance-scope :balance="statement.balances.netAssets" v-slot="{balance}">
            <balance-name-row :balance="balance" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label" :show-total="false"></balance-total-row>
        </balance-scope>

        <balance-scope :balance="statement.balances.capital" v-slot="{balance}">
            <balance-name-row :balance="balance" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                              :current-year="currentYear.label"
                              :show-total="false">
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label" :show-total="false"></balance-total-row>
        </balance-scope>
        </tbody>
    </table>
</template>

<script>
  import {month, year} from '@/app/filters';
  import BalanceScope from './table/BalanceScope.vue';
  import BalanceSpacerRow from './table/BalanceSpacerRow.vue';
  import BalanceNameRow from './table/BalanceNameRow.vue';
  import BalanceDataRow from './table/BalanceDataRow.vue';
  import BalanceTotalRow from './table/BalanceTotalRow.vue';
  import PeriodHeaders from '@/components/reports/table/PeriodHeaders.vue';
  import _find from 'lodash/find.js';

  export default {
    filters: {
      month,
      year,
    },

    props: {
      statement: {
        type: Object,
      },
      years: {
        type: Array,
      },
      year: {
        type: Object,
      },
      reportingPeriod: {
        type: Object,
      }
    },

    data() {
      return {};
    },

    computed: {
      currentYear() {
        return this.year;
      },
      reportingPeriods() {
        let currentReportingPeriod = _find(this.years, period => period === this.year);
        return currentReportingPeriod.reporting_periods.data;
      },
    },

    components: {
      BalanceScope,
      BalanceSpacerRow,
      BalanceNameRow,
      BalanceDataRow,
      BalanceTotalRow,
      PeriodHeaders,
    }
  };
</script>

<style scoped="true">
    table {
        table-layout: fixed;
    }
</style>