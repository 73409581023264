<template>
    <div v-if="statement">
        <page :key="key" v-for="(year, key) in years">
            <h2 class="text-lg px-4 py-4">Balance Sheet - {{ year.label }}</h2>
            <balance-sheet-year-table :statement="statement"
                                    :year="year"
                                    :years="years"
                                    :ref="addTableRef"
            ></balance-sheet-year-table>
        </page>
        <page>
            <h1 class="text-lg px-4 py-4">Balance Sheet - {{ firstYearLabel }} to {{ lastYearLabel }}</h1>
            <div>
                <statement-annual-summary :balances="statement.balances" :years="years" :labels="labels"/>
            </div>
        </page>
    </div>
</template>

<script>
  import BalanceSheetYearTable from '@/components/reports/BalanceSheetYearTable.vue';
  import Page from '@/components/exports/projection/Page.vue';
  import _maxBy from 'lodash/maxBy';
  import StatementAnnualSummary from '@/components/reports/StatementAnnualSummary.vue';
  import labels from '@/components/reports/labels';
  import useProjectionExport from './export-utils.js';
  import {computed, onMounted, ref, toRefs} from 'vue';

  export default {
    props: {
      export: {
        type: Object,
      }
    },

    setup(props) {
      const {export: projectionExport} = toRefs(props);

      const {firstYearLabel, lastYearLabel, years} = useProjectionExport(projectionExport);
      const statement = computed(() => projectionExport.value.statements.balanceSheet);

      const tables = ref([]);
      const addTableRef = (ref) => tables.value.push(ref);

      onMounted(() => {
        if(tables.value) {
          let max = _maxBy(tables.value, (table) => {
            return table.$el.clientWidth;
          });

          tables.value.forEach(table => {
            table.$el.style.minWidth = max.$el.clientWidth + 'px';
          });
        }
      })

      return {
        firstYearLabel,
        lastYearLabel,
        years,
        statement,
        labels: labels.balanceSheet,

        tables,
        addTableRef,
      }
    },

    components: {
      BalanceSheetYearTable,
      Page,
      StatementAnnualSummary,
    },
  };
</script>

<style scoped>
    table {
        /*table-layout: fixed;*/
        width: 100%;
    }
</style>