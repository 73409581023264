<template>
    <table class="text-gray-darkest lg:w-full print:w-full print:text-print-body" v-if="balances">
        <thead>
        <tr>
            <th class="px-4 py-1 text-left" colspan="3"></th>
            <th class="text-right px-4 py-1" v-for="(year, label) in years">{{ year.label }}<span
                    class="invisible-bracket"></span></th>
        </tr>
        <tr>
            <th class="px-4 py-1 text-left" colspan="3"></th>
            <th class="text-right px-4 py-1" v-for="(year, label) in years">£<span
                    class="invisible-bracket"></span></th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(balance, key) in balances">
            <balance-name-row :balance="{name: computedLabels[key]}" v-if="computedLabels[key]"></balance-name-row>
            <balance-spacer-row v-else/>
            <balance-data-row :balance="data"
                              :annual="true"
                              :key="balance.name + balanceKey"
                              v-for="(data, balanceKey) in balance.balances.data"
                              :show-total="false"
                              :highlight="!computedLabels[key] && balanceKey === balance.balances.data.length -1"
            >
            </balance-data-row>
            <balance-total-row
                    :balance="balance"
                    :label="'Total ' + computedLabels[key]"
                    :annual="true"
                    :show-total="false"
                    v-if="computedLabels[key]"
            ></balance-total-row>
        </template>
        </tbody>
    </table>
</template>

<script>
import BalanceSpacerRow from './table/BalanceSpacerRow.vue';
import BalanceNameRow from './table/BalanceNameRow.vue';
import BalanceDataRow from './table/BalanceDataRow.vue';
import BalanceTotalRow from './table/BalanceTotalRow.vue';
import _cloneDeep from 'lodash/cloneDeep';

export default {
    props: {
      balances: {
        type: Object,
      },
      years: {
        type: Array,
      },
      year: {
        type: String,
      },
      labels: {
        type: Object,
        default() {
          return {};
        }
      }
    },

    computed: {
      currentYear() {
        return this.year;
      },

      computedLabels() {
        return _cloneDeep(this.labels);
      },

      lastKey() {
        return Object.keys(this.balances)[Object.keys(this.balances).length - 1]
      }
    },
    components: {
      BalanceSpacerRow,
      BalanceNameRow,
      BalanceDataRow,
      BalanceTotalRow,
    },
  };
</script>

<style>

</style>