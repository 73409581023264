<template>
    <table v-if="balances" class="text-grey-darkest lg:w-full print:w-full print:text-print-body">
        <period-headers :reporting-periods="reportingPeriods"></period-headers>
        <tbody>
        <!-- Start -->
        <template v-for="balance in balances">
            <balance-name-row :balance="balance" :show-total="true" :number-of-reporting-periods="reportingPeriods.length"></balance-name-row>
            <balance-data-row v-for="(data, key) in balance.balances.data"
                              :balance="data"
                              :key="balance.name + key"
                              :current-year="currentYear.label">
            </balance-data-row>
            <balance-total-row :balance="balance" :current-year="currentYear.label"></balance-total-row>
        </template>
        </tbody>
        <!-- End -->
    </table>
</template>

<script>
  import {month, year} from '@/app/filters';
  import BalanceSpacerRow from './table/BalanceSpacerRow.vue';
  import BalanceNameRow from './table/BalanceNameRow.vue';
  import BalanceDataRow from './table/BalanceDataRow.vue';
  import BalanceTotalRow from './table/BalanceTotalRow.vue';
  import PeriodHeaders from '@/components/reports/table/PeriodHeaders.vue';
  import _find from 'lodash/find.js';

  export default {
    filters: {
      month,
      year,
    },

    props: {
      salesPlan: {
        type: Object,
      },
      years: {
        type: Array,
      },
      year: {
        type: Object,
      },
      reportingPeriod: {
        type: Object,
      },
    },

    data() {
      return {};
    },

    computed: {
      currentYear() {
        return this.year;
      },

      balances() {
        return this.salesPlan.balances;
      },

      reportingPeriods() {
        let currentReportingPeriod = _find(this.years, period => period === this.year);
        return currentReportingPeriod.reporting_periods.data;
      },
    },

    components: {
      BalanceSpacerRow,
      BalanceNameRow,
      BalanceDataRow,
      BalanceTotalRow,
      PeriodHeaders,
    }
  };
</script>

<style scoped>
    table {
        table-layout: fixed;
    }
</style>