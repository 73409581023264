<template>
    <div class="h-full w-full min-h-screen flex flex-col">
        <div class="page-break-before flex flex-col flex-1">
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>

<style>

</style>