<template>
    <tr class="no-bg mb-8 print:mb-3">
        <td class="text-gray-700 uppercase text-xs print:text-print-body" colspan="3">
            <div class="flex">
              <div class="mx-4 my-4 print:mt-3 print:mb-2 border-b w-auto whitespace-no-wrap font-medium"><slot></slot></div>
            </div>
        </td>
        <td :colspan="numberOfReportingPeriods"></td>
        <td v-if="showTotal" class="bg-gray-100"></td>
    </tr>
</template>

<script>
  export default {
    components: {
    },
    props: {
      numberOfReportingPeriods: {
        type: Number,
      },
      showTotal: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {};
    },
  };
</script>

<style>

</style>