<template>
    <div class="h-full w-full" v-if="statement">
        <page :key="key" v-for="(year, key) in years">
            <h2 class="text-lg px-4 py-4">Cash Flow - {{ year.label }}</h2>
            <cash-flow-year-table :ref="addTableRef" :year="year" :years="years"
                                  :cash-flow="statement"></cash-flow-year-table>
        </page>
      <!-- Only show summary if there is more than one year -->
      <page v-if="years.length > 1">
            <h1 class="text-lg px-4 py-4">Cash Flow - {{ firstYearLabel }} to {{ lastYearLabel }}</h1>
            <div>
                <statement-annual-summary :balances="statement.balances" :years="years" :labels="labels"/>
            </div>
        </page>
        <page v-if="projectionExport.options.visualizations">
          <h1 class="text-lg px-4 py-4" v-if="years.length > 1">Cash Flow - {{ firstYearLabel }} to {{ lastYearLabel }}</h1>
          <h1 class="text-lg px-4 py-4" v-else>Cash Flow - {{ firstYearLabel }}</h1>
          <div class="h-full w-full flex flex-1">
            <cash-flow-balance class="flex-1" :cash-flow="statement" ref="allTimeBalance"></cash-flow-balance>
          </div>
        </page>
    </div>
</template>

<script>
  import CashFlowYearTable from '@/components/reports/CashFlowYearTable.vue';
  import CashFlowBalance from '@/components/ui/cash-flow/charts/Balance.vue';
  import Page from '@/components/exports/projection/Page.vue';
  import _maxBy from 'lodash/maxBy';
  import StatementAnnualSummary from '@/components/reports/StatementAnnualSummary.vue';
  import labels from '@/components/reports/labels';

  import useProjectionExport from './export-utils.js';
  import {toRefs, onMounted, computed, ref, onBeforeUpdate} from 'vue';

  export default {
    props: {
      export: {
        type: Object,
      },
    },

    setup(props) {
      const {export: projectionExport} = toRefs(props);

      const {firstYearLabel, lastYearLabel, years} = useProjectionExport(projectionExport);
      const statement = computed(() => projectionExport.value.statements.cashFlow);

      const tables = ref([]);
      const addTableRef = (ref) => tables.value.push(ref);
      const allTimeBalance = ref();

      onMounted(() => {
        let max = _maxBy(tables.value, (table) => {
          return table.$el.clientWidth;
        });

        tables.value?.forEach(table => {
          table.$el.style.minWidth = max.$el.clientWidth + 'px';
        });

        if(allTimeBalance.value) {
          allTimeBalance.value.$el.style.width = max.$el.clientWidth + 'px';
        }
      })

      return {
        firstYearLabel,
        lastYearLabel,
        years,

        statement,
        labels: labels.cashFlow,

        tables,
        addTableRef,
        allTimeBalance,

        projectionExport,
      }
    },

    components: {
      CashFlowBalance,
      CashFlowYearTable,
      Page,
      StatementAnnualSummary,
    },
  };
</script>

<style scoped>
    table {
        /*table-layout: fixed;*/
        width: 100%;
    }
</style>